import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/splash.css';
import logo from '../assets/favicon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale, faHeart, faRing, faHandshake } from '@fortawesome/free-solid-svg-icons';

const SplashScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="splash-container">
      <div className="contact-email">
        <p>Contact us: <a href="mailto:support@naijasinglesconnection.com">support@naijasinglesconnection.com</a></p>
      </div>

      <div className="button-container">
        <button className="btn splash-btn" onClick={() => navigate('/login')}>Login</button>
        <button className="btn splash-btn" onClick={() => navigate('/signup')}>Create Account</button>
      </div>

      <div className="splash-content">
        <img src={logo} alt="Logo" className="splash-logo" />
        <h1 className="splash-title">Naija Singles Connection</h1>
        <p className="splash-subtitle">Nigeria's biggest dating site for true lovers</p>
      </div>

      <div className="sections">
        <div className="section-card" onClick={() => navigate('/dating-advice-men')}>
          <FontAwesomeIcon icon={faMale} className="section-icon" />
          <h2>Dating Advice for Men</h2>
          <p>Make a great first impression and build genuine connections.</p>
        </div>
        <div className="section-card" onClick={() => navigate('/dating-advice-women')}>
          <FontAwesomeIcon icon={faFemale} className="section-icon" />
          <h2>Dating Advice for Women</h2>
          <p>Find the right partner and nurture healthy relationships.</p>
        </div>
        <div className="section-card" onClick={() => navigate('/planning-honeymoon')}>
          <FontAwesomeIcon icon={faHeart} className="section-icon" />
          <h2>Planning Your Honeymoon</h2>
          <p>Discover dream destinations for a perfect honeymoon.</p>
        </div>
        <div className="section-card" onClick={() => navigate('/planning-marriage')}>
          <FontAwesomeIcon icon={faRing} className="section-icon" />
          <h2>Planning Your Marriage</h2>
          <p>Expert advice on making your wedding unforgettable.</p>
        </div>
        <div className="section-card" onClick={() => navigate('/during-courtship')}>
          <FontAwesomeIcon icon={faHandshake} className="section-icon" />
          <h2>During Courtship</h2>
          <p>Deepen your connection with insightful courtship tips.</p>
        </div>
      </div>

      <footer className="footer">
        <p>&copy; 2024 Olutom Global Tech</p>
      </footer>
    </div>
  );
};

export default SplashScreen;
