import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Message from './components/Message';
import Settings from './components/Settings';
import History from './components/History';
import Home from './components/Home';
import Profile from './components/Profile';
import NavBar from '../src/components/Navbar';
import Like from './components/Like';
import View from './components/View';
import Match from './components/Match';
import SubscriptionPaystack from './components/SubscriptionPaystack';
import PaystackPayment from './components/PaystackPayment';
import SplashScreen from './components/SplashScreen';
const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setLoading(false);
      if (!user) {
        signOut(auth).catch(error => {
          console.error('Error signing out: ', error);
        });
      }
    });

    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  return (
    <Router>
      {currentUser && <NavBar currentUser={currentUser} onLogout={() => signOut(auth)} />}
      <Routes>
        {/* Public Routes */}

        {/* Splash Screen Route */}
        <Route path="/" element={<SplashScreen />} />
        <Route path="/login" element={!currentUser ? <Login/> : <Navigate to="/home" />} />
        <Route path="/signup" element={!currentUser ? <SignUp /> : <Navigate to="/home" />} />

        {/* Protected Routes */}
        {currentUser ? (
          <>
            <Route path="/home" element={<Home currentUser={currentUser} />} />
            <Route path="/message/:userId" element={<Message />} />
            <Route path="/history" element={<History currentUser={currentUser} />} />
            <Route path="/settings" element={<Settings currentUser={currentUser} />} />
            <Route path="/profile" element={<Profile currentUser={currentUser} />} />
            <Route path="/like" element={<Like currentUser={currentUser} />} />
            <Route path="/view" element={<View currentUser={currentUser} />} />
            <Route path="/match" element={<Match currentUser={currentUser} />} />
            <Route path="*" element={<Navigate to="/home" />} /> {/* Redirect to home if no match */}
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect to login if not authenticated
        )}
      </Routes>
    </Router>
  );
};

export default App;
